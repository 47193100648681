import React from "react"

import PostLoopWithIntro from "../components/PostLoopWithIntro"
import Layout from "../components/layout"
import AllTags from "../components/AllTags"
import PostsByTag from "../components/PostsByTag"
import SEO from "../components/seo"
import useAllWordpressStickyPost from "../data/allWordpressStickyPost"
import useAllWordpressTag from "../data/allWordpressTag"
import useWordpressSiteMetaDataQuery from "../data/wordpressSiteMetadata"

const Homepage = props => {
  const allWordpressTag = useAllWordpressTag()
  const allWordpressStickyPost = useAllWordpressStickyPost()
  const wordpressSiteMetadata = useWordpressSiteMetaDataQuery()

  return (
    <Layout type="home">
      <SEO
        bodyClasses="home"
        title={`${wordpressSiteMetadata.name} - ${wordpressSiteMetadata.description}`}
      />

      {allWordpressStickyPost.edges.map(nodeObject => {
        const { node } = nodeObject
        const { id, slug, title, categories } = node
        return (
          <PostLoopWithIntro
            key={id}
            id={id}
            slug={slug}
            title={title}
            categories={categories}
          />
        )
      })}
      {allWordpressTag.edges.map(nodeObject => {
        const { node } = nodeObject
        const { id, slug } = node
        return (
          <section key={id} className="articles" id={slug} data-tag-name={slug}>
            <AllTags classes="articles__tags" currentTag={id} />
            <PostsByTag tagId={id} />
          </section>
        )
      })}
    </Layout>
  )
}

export default Homepage
