import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"

import { getAuthors } from "../helpers/post"

const PostLoopWithIntro = props => {
  const { slug, title, categories } = props

  return (
    <article className="articles__item articles__item--introduction">
      <AniLink cover bg="#f5f5f5" duration={0.1} to={slug}>
        <h3 className="articles__item__title">{title}</h3>
        <p>{getAuthors(categories)}</p>
      </AniLink>
    </article>
  )
}

PostLoopWithIntro.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  categories: PropTypes.instanceOf(Object).isRequired,
}

PostLoopWithIntro.defaultProps = {}

export default PostLoopWithIntro
