import { graphql, useStaticQuery } from "gatsby"

const AllWordpressPosts = () => {
  const { allWordpressPost } = useStaticQuery(graphql`
    query {
      allWordpressPost(filter: { status: { eq: "publish" } }) {
        edges {
          node {
            id
            link
            slug
            title
            status
            tags {
              id
              slug
              name
              path
            }
            categories {
              id
              name
              slug
              path
            }
          }
        }
      }
    }
  `)

  return allWordpressPost
}

export default AllWordpressPosts
