import React from "react"
import PropTypes from "prop-types"
import filter from "lodash/filter"

import PostLoop from "./PostLoop"
import useAllWordpressPosts from "../data/allWordpressPosts"

const PostsByTag = props => {
  const { tagId } = props
  const allWordpressPosts = useAllWordpressPosts()
  return allWordpressPosts.edges.length > 0
    ? allWordpressPosts.edges.map(nodeObject => {
        const { node } = nodeObject
        const { id, slug, title, categories, tags } = node
        if (filter(tags, ["id", tagId]).length > 0) {
          return (
            <PostLoop
              key={id}
              id={id}
              slug={slug}
              title={title}
              categories={categories}
            />
          )
        }
        return null
      })
    : null
}

PostsByTag.propTypes = {
  tagId: PropTypes.string.isRequired,
}

PostsByTag.defaultProps = {}

export default PostsByTag
