import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"

import { getAuthors } from "../helpers/post"

const PostLoop = props => {
  const { slug, title, categories } = props

  return (
    <article
      className="articles__item"
      data-sal="slide-up"
      data-sal-delay="300"
      data-sal-easing="ease"
    >
      <AniLink cover bg="#f5f5f5" duration={0.1} to={slug}>
        <h3 className="articles__item__title">{getAuthors(categories)}</h3>
        <p>{title}</p>
      </AniLink>
    </article>
  )
}

PostLoop.propTypes = {
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  categories: PropTypes.instanceOf(Object).isRequired,
}

PostLoop.defaultProps = {}

export default PostLoop
