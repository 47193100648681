import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import scrollTo from "gatsby-plugin-smoothscroll"

import useAllWordpressTag from "../data/allWordpressTag"

const AllTags = props => {
  const allWordpressTag = useAllWordpressTag()
  const { classes, currentTag } = props

  if (allWordpressTag.edges.length > 0) {
    return (
      <ul className={classes}>
        {allWordpressTag.edges.map(nodeObject => {
          const { node } = nodeObject
          const { id, name, slug, count } = node
          return (
            <li key={id} className={currentTag === id ? "current" : ""}>
              <a
                className={clsx("btn", { "btn--active": currentTag === id })}
                href={`#${slug}`}
                onClick={e => {
                  e.preventDefault()
                  scrollTo(`#${slug}`, { behavior: "smooth" })
                }}
              >
                {name}
                <span className="counter">{count}</span>
              </a>
            </li>
          )
        })}
      </ul>
    )
  }
}

AllTags.propTypes = {
  classes: PropTypes.string.isRequired,
  currentTag: PropTypes.string,
}

AllTags.defaultProps = {
  currentTag: "0",
}

export default AllTags
